<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="申请修改丧属"
      @ok="handleSubmit"
    >
      <span style="margin-left:30px;color: red;">您对此参数的修改次数已经达到三次，本次修改需要进行申请，申请通过后将自动变更为修改后结果。</span>
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="原丧属">
          <a-input
            :disabled="true"
            v-decorator="['old_name', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="原丧属联系电话">
          <a-input
            :disabled="true"
            v-decorator="['old_phone', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="新丧属">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入新丧属姓名' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="新丧属联系电话">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入新丧属联系电话' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' },
                { max: 15, message: '最多15个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="修改原因">
          <a-input
            v-decorator="['reason', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入修改原因' },
                { max: 15, message: '最多15个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="审核人">
          <a-select
            placeholder="请选择审核人"
            @focus="fetchUserOptions"
            allow-clear
            v-decorator="['user_id', {
              initialValue: userDefaultId,
              rules: [{ required: true, message: '请选择审核人' }]
            }]"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findTenantAdministrativeOptions } from '@/api/user'
import { modifyMaster } from '@/api/order'

export default {
  name: 'ModifyMaster',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'modify_master' }),
      submitting: false,
      userOptions: [],
      userDefaultId: '', // 审核默认id
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({ old_name: this.name, old_phone: this.phone })
      })
    },
    fetchUserOptions() {
      findTenantAdministrativeOptions().then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data
          if (this.userOptions.length > 0) {
            this.userDefaultId = this.userOptions[0].id
          }
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          modifyMaster(Object.assign({ order_id: this.id }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
