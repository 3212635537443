var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"申请修改丧属"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('span',{staticStyle:{"margin-left":"30px","color":"red"}},[_vm._v("您对此参数的修改次数已经达到三次，本次修改需要进行申请，申请通过后将自动变更为修改后结果。")]),_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"原丧属"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['old_name', {
            normalize: this.$lodash.trim
          }]),expression:"['old_name', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"原丧属联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['old_phone', {
            normalize: this.$lodash.trim
          }]),expression:"['old_phone', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"新丧属"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入新丧属姓名' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入新丧属姓名' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"新丧属联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入新丧属联系电话' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' },
              { max: 15, message: '最多15个字符' } ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入新丧属联系电话' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '手机号码格式不正确' },\n              { max: 15, message: '最多15个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"修改原因"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入修改原因' },
              { max: 15, message: '最多15个字符' } ]
          }]),expression:"['reason', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入修改原因' },\n              { max: 15, message: '最多15个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"审核人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
            initialValue: _vm.userDefaultId,
            rules: [{ required: true, message: '请选择审核人' }]
          }]),expression:"['user_id', {\n            initialValue: userDefaultId,\n            rules: [{ required: true, message: '请选择审核人' }]\n          }]"}],attrs:{"placeholder":"请选择审核人","allow-clear":""},on:{"focus":_vm.fetchUserOptions}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }